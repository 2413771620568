// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-posts-using-context-for-accordion-mdx": () => import("./../../../src/pages/posts/using-context-for-accordion.mdx" /* webpackChunkName: "component---src-pages-posts-using-context-for-accordion-mdx" */),
  "component---src-pages-posts-when-to-use-use-callback-mdx": () => import("./../../../src/pages/posts/when-to-use-useCallback.mdx" /* webpackChunkName: "component---src-pages-posts-when-to-use-use-callback-mdx" */)
}

